function textToChars(text: string) {
  return text.split('').map((c: string) => c.charCodeAt(0));
}

function byteHex(n: any) {
  return ('0' + Number(n).toString(16)).slice(-2);
}

function applySaltToChar(code: any, salt: string) {
  return textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
}

export function encrypt(salt: any, text: string) {
  return text
    .split('')
    .map(textToChars)
    .map((code: any) => applySaltToChar(code, salt))
    .map(byteHex)
    .join('');
}

export function decrypt(salt: string, encoded: string) {
  return encoded
    .match(/.{1,2}/g)!
    .map((hex: string) => parseInt(hex, 16))
    .map((code: any) => applySaltToChar(code, salt))
    .map((charCode: number) => String.fromCharCode(charCode))
    .join('');
}
